import React, {Component, useState, useEffect} from 'react';
import MaterialTable, {MTableToolbar} from 'material-table';
import {fetchSchedulers} from '../../Services/scheduler';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {deleteScheduler, changeStatus} from '../../Services/scheduler';

const axios = require('axios');
const {BASE_URL} = require('../../utils/Url');

function MaterialTableDemo(props) {
    let data = props.schedulers.slice(0, 2);
    useEffect(() => {
        // data.forEach((row, index) => {
        //     axios
        //         .post(`${BASE_URL}/getNextTime`, {
        //             ScheduledDateTime: row.ScheduledDateTime,
        //         })
        //         .then(res => {
        //             data[index].nextRun = res.data.toString().slice(0, 33);
        //             setLoading(loading + 1);
        //         });
        // });
    });
    const [loading, setLoading] = React.useState(0);
    const [state, setState] = React.useState({
        columns: [
            {title: 'Name', field: 'Name'},
            // {
            //   title: 'Active',
            //   field: 'Active',
            //   render: rowData => (
            //     <button
            //       className={
            //         rowData.Active == '1'
            //           ? 'btn btn-sm btn-success'
            //           : 'btn btn-sm btn-danger'
            //       }
            //       onClick={e => {
            //         var Status = 0;
            //         if (rowData.Active == '1') {
            //           Status = 0;
            //         } else {
            //           Status = 1;
            //         }
            //         props.changeStatus(rowData.ID, Status);
            //       }}
            //     >
            //       {rowData.Active == '1' ? 'Deactivate' : 'Activate'}
            //     </button>
            //   ),
            // },
            {title: 'Next Run', field: 'nextRun'},
        ],
    });
    return (
        <div>
            {loading == '2' ? (
                ''
            ) : (
                <MaterialTable
                    title="Schedulers"
                    columns={state.columns}
                    data={data}
                    options={{
                        search: false,
                        paging: false,
                    }}
                />
            )}
        </div>
    );
}

class Scheduler extends Component {
    componentDidMount() {
        this.props.fetchSchedulers();
    }

    render() {
        return (
            <div>
                <MaterialTableDemo {...this.props} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    schedulers: state.schedulerReducer.schedulers,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchSchedulers: fetchSchedulers,
            deleteScheduler: deleteScheduler,
            changeStatus: changeStatus,
        },
        dispatch,
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Scheduler);
