import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Link,
    withRouter,
    Redirect,
} from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BASE_URL, AUTH_URL,commonRoutes } from '../../utils/Url';
import Cookies from "js-cookie";
import { getLoggedUserData } from "../../Services/configurations";
import { saveLoggedInUser } from "../../Services/user";

const connected = Cookies.get("connect.sid");
const authToken = true;
// store.getState().configurations.isAuthenticated
const PrivateRoute = (props) => {
    const { component: Component, ...rest } = props;
    const [formState, setFormState] = React.useState({
        returnCompt: [<Component {...props} />],
    });

    React.useEffect(() => {
        if (connected) {
            if (localStorage.getItem("isAuthenticated")) {
                getLoggedUserData(res => {
                    if (res) {
                        if (res.data && res.data.userobject && res.data.userobject.userInfo && res.data.userobject.userInfo.Id) {
                            localStorage.setItem("UserInfo", JSON.stringify(res.data.userobject.userInfo))
                            localStorage.setItem("isAuthenticated", true)
                            props.saveLoggedInUser(true)
                            if (JSON.parse(localStorage.getItem("UserInfo")).RoleId === 2) {
                                if (commonRoutes.includes(props.location.pathname))
                                    setFormState({
                                        returnCompt: [
                                            <Route
                                                {...rest}
                                                render={(matchProps) => (
                                                    <Component {...matchProps} />
                                                )}
                                            />,
                                        ],
                                    });
                                else
                                    setFormState({
                                        returnCompt: [<Redirect to="/dashboard" />],
                                    });
                            } else
                                setFormState({
                                    returnCompt: [
                                        <Route
                                            {...rest}
                                            render={(matchProps) => (
                                                <Component {...matchProps} />
                                            )}
                                        />,
                                    ],
                                });
                        }
                        else
                            window.location.replace(`${AUTH_URL}/auth/openid`)
                    }
                })
            } else {
                getLoggedUserData(res => {
                    if (res) {
                        console.log("RESULT FROM USER SERVICE IN ELSE")
                        if (res.data && res.data.userobject && res.data.userobject.userInfo && res.data.userobject.userInfo.Id) {
                            localStorage.setItem("isAuthenticated", true)
                            props.saveLoggedInUser(true)
                            const { component: Component, ...rest } = props;
                            if (props.location.pathname === "/sign-in") {
                                setFormState({
                                    returnCompt: [<Redirect to="/dashboard" />],
                                });
                            } else {
                                setFormState({
                                    returnCompt: [
                                        <Route
                                            {...rest}
                                            render={(matchProps) => (
                                                <Component {...matchProps} />
                                            )}
                                        />,
                                    ],
                                });
                            }
                        }
                        else
                            window.location.replace(`${AUTH_URL}/auth/openid`)

                    }
                    else
                        window.location.replace(`${AUTH_URL}/auth/openid`)
                })
            }
        } else
            window.location.replace(`${AUTH_URL}/auth/openid`)
    }, [props.location.pathname])

    return formState.returnCompt;
};

const mapStateToProps = state => ({
    loginStatus: state.user_reducer.userLoggedIn
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            saveLoggedInUser: saveLoggedInUser
        },
        dispatch
    );

var connectredux = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PrivateRoute);

export default withRouter(connectredux);
