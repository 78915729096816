import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const { LOGS_URL } = require("../../utils/Url");
const axios = require("axios");
const apiAdapter  = require('../../utils/APIAdapter').apiAdapter;
const getOptions=require("../../Services/connection").getOptions;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

function DownloadLogs(props) {
    const classes = useStyles();
    const [files,setFiles] = React.useState([0])
    const [checked, setChecked] = React.useState([]);
    const [searchFiles, setSearchFiles] = React.useState([]);

    React.useEffect(()=>{
        fetchLogs()
    },[])

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        setChecked(newChecked);
    };
    
    const fetchLogs = () => {
        let logFiles = []
        const api=apiAdapter()
        
        axios.get(`${LOGS_URL}/logs/file`,getOptions()).then(res => {
            res.data.map(row => {
                logFiles.push(row)
            });
            setFiles(logFiles);
            setSearchFiles(logFiles);
        });
    };

    const searchFile = (value) => {
        setSearchFiles(files.filter(name=> name.includes(value)))
    }

    const checkAll = (event) => {
        if(event.target.checked === true)
            setChecked(files.slice())
        else
            setChecked([])
    }

    const downloadFiles = () => {
        window.open(LOGS_URL + "/logs/" + checked+"?User="+ localStorage.getItem("UserInfo"));
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} checked={checked.length === files.length} onChange={(event)=>checkAll(event)} /> &nbsp; &nbsp; &nbsp; 
                    <TextField onChange={(event)=>searchFile(event.target.value)} id="outlined-basic" label="Search File" variant="outlined"  />
                </Grid>
                <Grid item xs={6} container direction="row" justify="flex-end">
                    <Button disabled={checked.length === 0} onClick={downloadFiles} variant="contained" color="primary">
                        Download
                    </Button>
                </Grid>
            </Grid>
            <br/>
            <List className={classes.root}>
                {searchFiles.map((value) => {
                const labelId = `checkbox-list-label-${value}`;
        
                return (
                    <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
                        <ListItemIcon>
                        <Checkbox
                            edge="start"
                            checked={checked.indexOf(value) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                        />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${value}`} />
                    
                    </ListItem>
                );
                })}
            </List>
        </>
    );
}

export default DownloadLogs

