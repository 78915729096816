import {
    fetchStatusPending,
    fetchStatusSuccess,
    fetchStatusError,
    updateStatusError,
    updateStatusPending,
    updateStatusSuccess,
} from '../actions/messagestatus-actions';

const apiAdapter = require('../utils/APIAdapter').apiAdapter;;
const {BASE_URL} = require('../utils/Url');

// const axios = require('axios');

export function fetchStatuses(ID) {
    return dispatch => {
        dispatch(fetchStatusPending());

        // setTimeout(function() {
        //   dispatch(fetchStatusSuccess(statuses));
        // }, 1000);

        const api = apiAdapter(`${BASE_URL}/messages/${ID}/status`);

        api
            .get()
            .then(res => {
                // console.log(res.data.result);
                if (res.error) {
                    throw res.error;
                } else {
                    dispatch(fetchStatusSuccess(res.data.result));
                }
            })
            .catch(error => {
                dispatch(fetchStatusError(error));
            });
    };
}

export function updateStatus(ID) {
    return dispatch => {
        dispatch(updateStatusPending());

        const api = apiAdapter(`${BASE_URL}/messages/updateStatus/${ID}`);

        api
            .get()
            .then(res => {
                if (res.error) {
                    throw res.error;
                } else {
                    dispatch(updateStatusSuccess(res.data.newStatus));
                }
            })
            .catch(error => {
                dispatch(updateStatusError(error));
            });
    };
}
