import React, { Component } from "react";
import ChartComponent from "../../Components/Charts";
import SchedulerComponent from "../../Components/DashboardTables/Scheduler";
import MessageComponent from "../../Components/DashboardTables/messages";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CompletionTimeComponent from "../../Components/CompletionTimeChart";
import Loading from "../../Components/Loading";
import Graph from './Graphs';
import './Graphs/Graphs.css';
const { apiAdapter } = require('../../utils/APIAdapter');

const { BASE_URL } = require("../../utils/Url");

const MainCard = () => ({
  minWidth: "150px",
  paddingRight: "20px",
  paddingLeft: "20px"
});

const completeBorder = () => ({
  border: "1px solid rgba(108,193,115,1)"
});

const PendingBorder = () => ({
  border: "1px solid rgba(255,233,0,1)"
});

const AllBorder = () => ({
  border: "1px solid rgba(63,81,181,1)"
});

const FailedBorder = () => ({
  border: "1px solid",
  borderColor: "rgba(214,89,89,1)"
});

const CompleteTitle = () => ({
  color: "white",
  textAlign: "center",
  backgroundColor: "rgba(108,193,115,1)"
});

const PendingTitle = () => ({
  color: "white",
  textAlign: "center",
  backgroundColor: "rgba(255,233,0,1)"
});

const FailedTitle = () => ({
  color: "white",
  textAlign: "center",
  backgroundColor: "rgba(214,89,89,1)"
});

const AllTitle = () => ({
  color: "white",
  textAlign: "center",
  backgroundColor: "rgba(63,81,181,1)"
});

const CompleteTypography = () => ({
  textAlign: "center",
  fontSize: "30px",
  color: "rgba(108,193,115,1)"
});

const pendingTypography = () => ({
  textAlign: "center",
  fontSize: "30px",
  color: "rgba(255,233,0,1)"
});

const failedTypography = () => ({
  textAlign: "center",
  fontSize: "30px",
  color: "rgba(214,89,89,1)"
});

const allTypography = () => ({
  textAlign: "center",
  fontSize: "30px",
  color: "rgba(63,81,181,1)"
});

class Home extends Component {
  state = {
    completedMessages: "",
    allMessages: "",
    failedMessages: "",
    pendingMessages: "",
    oneToFive: "",
    fivetoTen: "",
    tenToFifteen: "",
    fetchData: false
  };

  fetchStatistics = () => {
    // axios.interceptors.response.use(
    //     (response) => {
    //       if (
    //         response &&
    //         response.data &&
    //         response.data.message == "AuthenticationFailure"
    //       ) {
    //         window.location.replace(`${"http://localhost:4000"}/api/auth/openid`);
    //       } else {
    //         return response;
    //       }
    //     },
    //     (error) => {
    //       if (
    //         error.response &&
    //         error.response.data &&
    //         error.response.data.message == "AuthenticationFailure"
    //       ) {
    //         window.location.replace(`${"http://localhost:4000"}/api/auth/openid`);
    //       } else {
    //         if(error.response && error.response.data){
    //         //   let string = constructErrorMesssage(error.response.data);
    //         //   store.dispatch(setServerErrorFlag(true))
    //         //   store.dispatch(setServerErrorMessage(string));
    //         //   setTimeout(()=>{
    //         //     store.dispatch(setServerErrorFlag(false))
    //         //     store.dispatch(setServerErrorMessage(""))
    //         //   },3000)
    //         console.log("============================")
    //         console.log(error.response)
    //         console.log("============================")
    //         }
    //         return Promise.reject(error);
    //       }
    //     }
    // );
    const api = apiAdapter(BASE_URL);
    api.get(`/statistics/fetchAll`).then(res => {
      //if(res.success){
      const data = res.data.result;
      this.setState({
        ...this.state,
        completedMessages: data[0].Value,
        allMessages: data[3].Value,
        failedMessages: data[1].Value,
        pendingMessages: data[2].Value,
        oneToFive: data[4].Value,
        fivetoTen: data[5].Value,
        tenToFifteen: data[6].Value,
        fetchData: true
      });
      //}
    });
  };
  componentWillMount = () => {
    this.fetchStatistics();
  };
  render() {
    return (
      <div className="container dashboard-body">
          <Graph/>
      </div>
    );
  }
}

export default Home;
