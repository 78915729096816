import axios from 'axios';
import {store} from '../index';
import {SERVER_URL,AUTH_URL} from "./Url";
import Cookies from "js-cookie";
const uuidv1 = require("uuid/v1");

const connected = Cookies.get("connect.sid");
const getLanguage = () => store.getState().lp_reducer.activeLang || 'SV';

const getJwt = () => {
    const { activeUser } = store.getState().user_reducer;

    if (activeUser) {
        return activeUser.jwt;
    }
    return '';
};



export const getOptions = () => {
    return {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            Authorization: "getJwt()",
            "Content-Type": "application/json",
            traceId: uuidv1(),
            Cookie: connected,
        },
    }
}

const prepareUrl = (api) => `${AUTH_URL}${api}`;

const wrapper = {
    get: (api) => axios.get(prepareUrl(api), getOptions()),
    post: (api, formData = {}) => axios.post(prepareUrl(api), formData, getOptions()),
    put: (api, formData = {}) => axios.put(prepareUrl(api), formData, getOptions()),
    delete: (api) => axios.delete(prepareUrl(api), getOptions()),
};

export default wrapper;
