import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Redirect} from 'react-router-dom';
import {withRouter} from 'react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {changeAuthentication} from '../../Services/configurations';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {store} from '../../index';

const axios = require('axios');
const {BASE_URL} = require('../../utils/Url');

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function Form(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        Username: '',
        Password: '',
        redirect: false,
    });

    function setRedirect(event) {
        event.preventDefault();
        axios
            .post(BASE_URL + '/user/validate', {
                Username: state.Username,
                Password: state.Password,
            })
            .then(res => {
                if (res.data.result === true) {
                    props.changeAuthentication(true);
                    localStorage.setItem('isAuthenticated', true);
                    localStorage.setItem('lastLogin', Date.now());
                    console.log("Date.now()-1574846435360");
                    console.log(Date.now());
                    console.log(Date.now() - 1574846435360);
                    setState({
                        ...state,
                        redirect: true,
                    });
                    // props.history.push('/dashboard');
                } else {
                    NotificationManager.error('Invalid username or password', 'Error');
                }
            });
    }

    function renderRedirect() {
        if (state.redirect) {
            return <Redirect to="/dashboard"/>;
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <NotificationContainer/>
            {renderRedirect()}
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                {/* eslint-disable-next-line no-restricted-globals */}
                <form onSubmit={() => setRedirect(event)} className={classes.form}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Username"
                        name="email"
                        autoComplete="email"
                        value={state.Username}
                        onChange={() =>
                            setState({
                                ...state,
                                // eslint-disable-next-line no-restricted-globals
                                Username: event.target.value,
                            })
                        }
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={state.Password}
                        onChange={() =>
                            setState({
                                ...state,
                                // eslint-disable-next-line no-restricted-globals
                                Password: event.target.value,
                            })
                        }
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                    </Button>
                </form>
            </div>
        </Container>
    );
}

class SignIn extends React.Component {
    render() {
        return (
            <div>
                <Form {...this.props} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.configurations.isAuthenticated,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            changeAuthentication: changeAuthentication,
        },
        dispatch,
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));
