import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { NotificationManager } from "react-notifications";
import { LOGS_URL } from "../../utils/Url";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { TextField } from "@material-ui/core";

const axios = require("axios");
const { BASE_URL } = require("../../utils/Url");
const apiAdapter = require('../../utils/APIAdapter').apiAdapter;
const getOptions=require("../../Services/connection").getOptions;
// const Successnotification = createNotification('success');
// const ErrorNotification = createNotification('error');

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap"
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
      // width: '100%',
    }
}));

class LoggerFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      files: [],
      fetchFiles: false,
      downloadPending: false,
      fileName: "",
      address: "",
      scroll: 'paper'
    };
  }

  updateSelectValue = event => {
    this.setState({
      fileName: event.target.value
    });
  };

  handleMenuItemClick = event => {
    this.setState({
      fileName: event.target.value
    });
  };

  fetchAddress = () => {
    let directoryRow;
    const api = apiAdapter(BASE_URL);
    
    axios.get(`/configurations/fetchAll`).then(res => {
      let address = res.data.result.find(row => {
        if (row.Name == "LogsDirectoryPath") {
          return row.Value;
        }
      });
      console.log(res.data.result);
    });
  };

  fetchFiles = () => {
    let files;
    const api=apiAdapter()
    axios.get(`${LOGS_URL}/logs/file`,getOptions()).then(res => {
      res.data.map(row => {
        this.setState({
          files: [...this.state.files, row]
        });
      });
      this.setState({
        fetchFiles: true
      });
    });
  };

  componentDidMount() {
    this.fetchFiles();
  }

  validateFileName = () => {
    if (this.state.fileName === "")
      NotificationManager.error("Please select a file", "Error");
    else {
      window.open(LOGS_URL + "/logs/" + this.state.fileName, "_blank");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          // disableBackdropClick
          // disableEscapeKeyDown
          open={this.state.open}
          onClose={this.props.closeModal}
          fullWidth
          scroll={this.state.scroll}
        >
          <DialogTitle style={{ backgroundColor: "#3f51b5", color: "white" }}>
            <Grid container>
              <Grid item xs={12} sm={12} md={10} lg={10} lg={10}>
                Download Logs
              </Grid>

              <Grid item xs={12} sm={12} md={2} lg={2} lg={2} container direction="row" justify="flex-end" >
                <IconButton onClick={this.props.closeModal} aria-label="delete" size="small">
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <FormControl className={classes.formControl} fullWidth>

              {this.state.fetchFiles === true && (
                <TextField
                  required
                  select
                  label="Select File"
                  variant="outlined"
                  value={this.state.fileName}
                  onChange={this.handleMenuItemClick}
                >
                  {this.state.files.map(row => {
                    return (
                      <MenuItem
                        selected={this.state.fileName === row}
                        key={row}
                        value={row}
                      >
                        {row}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.validateFileName} color="primary">
              Download
            </Button>

          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(useStyles)(LoggerFiles);
