import React, { Component, useEffect } from 'react';
import Cron from 'react-cron-generator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.css';
import { makeStyles } from '@material-ui/core/styles';
import { fetchEndpoints } from '../../Services/endpoint';
import { createScheduler, updateScheduler } from '../../Services/scheduler';
import CronBuilder from 'react-cron-builder';
import 'react-cron-builder/dist/bundle.css';
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Snackbar from "../Snackbar";
import './popup.css'


const apiAdapter = require('../../utils/APIAdapter').apiAdapter;

var cronstrue = require('cronstrue');
const { BASE_URL } = require('../../utils/Url');
const api = apiAdapter(`${BASE_URL}/endpoints/fetchAll`);


const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

class SchedulerForm extends React.Component {
    constructor(props) {
        console.log(props);
        debugger;
        super(props);

        this.state = {
            Id: '',
            name: '',
            endpointId: '',
            endpointName: '',
            cronValue: '',
            execute: '',
            selected: false,
            snackBar: false,
            snackBarMsg: ''
        };
    }

    componentWillMount = () => {
        this.setState({ endpointName: '' });
        if (this.props.location.state.schedulerData.EndpointName == null) {
            this.setState({
                Id: this.props.location.state.schedulerData.ID,
                name: this.props.location.state.schedulerData.Name,
                endpointId: this.props.location.state.schedulerData.EndpointId,
                endpointName: 'GLOBAL',
                cronValue: this.props.location.state.schedulerData.ScheduledDateTime,
                execute: this.props.location.state.schedulerData.Execute,
            })

        }
        else {
            this.setState({
                Id: this.props.location.state.schedulerData.ID,
                name: this.props.location.state.schedulerData.Name,
                endpointId: this.props.location.state.schedulerData.EndpointId,
                endpointName: this.props.location.state.schedulerData.EndpointName,
                cronValue: this.props.location.state.schedulerData.ScheduledDateTime,
                execute: this.props.location.state.schedulerData.Execute,
            });
        }
        console.log(this.state);

        console.log(typeof this.state.endpointName);
    };
    isUrl = (str) => {
        RegExp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        if (RegExp.test(str)) {
            return true;
        }
        else {
            return false;
        }
    }
    updateName = event => {
        this.setState({ name: event.target.value });
    };
    updateEndpointId = (event, child) => {
        // if()
        this.setState({ endpointId: child.props.id });
        var index = event.nativeEvent.target.selectedIndex;
        this.setState({ endpointName: event.target.value });
    };
    updateCronValue = event => {
        this.setState({ cronValue: event.target.value });
    };
    updateExecute = event => {
        this.setState({ execute: event.target.value });
    };
    showSnackBar = event => {
        return (< Snackbar message={"Schedular Created Successfully "} severity={"success"} />)
    }
    onCreateScheduler = event => {
        event.preventDefault();
        // if (!this.validateUrl(this.state.execute)) {
        //     return;
        // }
        this.props.createScheduler(
            this.state.name,
            this.state.cronValue,
            this.state.execute,
            this.state.endpointId,
        );
        this.setState({
            snackBar: true
        })
        setTimeout(() => {
            this.props.history.push('/scheduler')
        }, 1000)
    };
    onEditScheduler = () => {

        // eslint-disable-next-line no-restricted-globals
        event.preventDefault();
        // if (!this.validateUrl(this.state.execute)) {
        //     return;
        // }

        this.props.updateScheduler(
            this.state.Id,
            this.state.name,
            this.state.endpointId,
            this.state.cronValue,
            this.state.endpointName,
            this.state.execute,
        );
        this.setState({
            snackBar: true
        })
        setTimeout(() => {
            this.props.history.push('/scheduler')
        }, 1000)

    };

    render() {

        const centeredDiv = { display: "block", padding: "15px" };
        return (
            <div style={centeredDiv} >
                <form >
                    <div className="form-group" style={{ paddingTop: "15px" }}>

                        <div className="col-sm-6">
                            <TextField
                                required
                                type="text"
                                label="Name"
                                className="form-control"
                                variant="outlined"
                                name="name"
                                value={this.state.name}
                                onChange={this.updateName}
                                disabled={this.props.location.state.actionType == 'Edit'}
                            />
                        </div>
                    </div>
                    <div className="form-group" style={{ paddingTop: "15px" }}>

                        <div className="col-sm-6">
                            <TextField
                                required
                                type="text"
                                className="form-control"

                                label="Execute URL"
                                className="form-control"
                                variant="outlined"
                                name="execute"
                                value={this.state.execute}
                                onChange={this.updateExecute}
                            />
                        </div>
                    </div>

                    <div className="form-group">

                        <div className="col-sm-6" style={{ "paddingTop": "15px" }}>
                            <TextField
                                required
                                type="text"
                                className="form-control"

                                label="Enter Cron"
                                className="form-control"
                                variant="outlined"
                                name="cron"
                                value={this.state.cronValue}
                                onChange={this.updateCronValue}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        {/*<TextField
                            select
                            label="Type"
                            required
                            onChange={this.updateEndpointId}
                            className="form-control"
                            name="endpointId"
                            value={this.state.endpointName ? this.state.endpointName : this.props.endpoints[0]}
                            style={{ width: '100%', marginTop: 15 }}
                        >
                            {this.props.endpoints.map(option => (
                                <MenuItem key={option.Id} id={option.Id} value={option.Id}>
                                    {option.Name}
                                </MenuItem>
                            ))}
                        </TextField>*/}
                        {/* <label className="control-label col-sm-2" htmlFor="pwd">
                            Endpoint:
                            </label> */}
                        <div className="col-sm-6">
                            <TextField
                                select
                                label="Select Endpoint"
                                name="endpointId"
                                variant="outlined"
                                value={this.state.endpointName}
                                onChange={this.updateEndpointId}
                                style={{ width: '100%', marginTop: 15 }}
                            >
                                <MenuItem id={null} value="GLOBAL">
                                    GLOBAL
                                </MenuItem>
                                {this.props.endpoints.map(option => (
                                    <MenuItem key={option.Id} id={option.Id} value={option.Name}>
                                        {option.Name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {/* <select

                                label="Execute URL"
                                variant="outlined"
                                className="form-control"
                                required
                                onChange={this.updateEndpointId}
                                name="endpointId"
                            >
                                <option
                                    selected={
                                        typeof this.state.endpointName === undefined
                                            ? true
                                            : this.state.endpointName === 'object'
                                    }
                                    value="null"
                                    selected={false}
                                >
                                    {console.log(typeof this.state.endpointName)}
                                        Global
                                    </option>
                                {this.props.endpoints.map(row => {
                                    return (
                                        <option
                                            selected={this.state.endpointName == row.Name}
                                            key={row.Id}
                                            value={row.Id}
                                        >
                                            {row.Name}
                                        </option>
                                    );
                                })}
                            </select> */}

                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-sm-6" htmlFor="pwd">
                            Schedule Date Time:
                            </label>
                        <div className="col-sm-6">
                            <CronBuilder

                                style={{ "border": "1 px solid" }}
                                cronExpression={this.state.cronValue}
                                onChange={event => {
                                    this.setState({ cronValue: event });
                                }}
                                showResult={true}
                            />
                        </div>
                    </div>

                    <div className="form-group" className="col-sm-6">
                        <div style={{ "textAlign": "right" }}>
                            <Button
                                type="submit"
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={
                                    this.props.location.state.actionType == 'Create'
                                        ? this.onCreateScheduler
                                        : this.onEditScheduler
                                }
                            >
                                {this.props.location.state.actionType == 'Create'
                                    ? 'Save Scheduler'
                                    : 'Save Scheduler'}
                            </Button>
                            {this.state.snackBar ? < Snackbar message={"Schedular successfully saved"} severity={"success"} /> : null}

                        </div>
                    </div>
                </form>
            </div >
        );
    }
}

// const useStyles = makeStyles(theme => ({
//   container: {
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   formControl: {
//     margin: theme.spacing(1),
//   },
//   root: {
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));

// function ComposedTextField(props) {
//   const [Id, setId] = React.useState('');
//   const [name, setName] = React.useState('');
//   const [endpointId, setEndpointId] = React.useState(0);
//   const [endpointName, setEndpointName] = React.useState('');
//   const [cronValue, setCronValue] = React.useState('');
//   const [execute, setExecute] = React.useState('');
//   const classes = useStyles();

//   useEffect(() => {
//     setId(props.location.state.schedulerData.ID);
//     setName(props.location.state.schedulerData.Name);
//     setEndpointName(props.location.state.schedulerData.EndpointName);
//     setCronValue(props.location.state.schedulerData.ScheduledDateTime);
//     setEndpointId(props.location.state.schedulerData.EndpointId);
//     setExecute(props.location.state.schedulerData.Execute);
//     console.log(endpointName);
//     console.log(
//       cronstrue.toString(props.location.state.schedulerData.ScheduledDateTime),
//     );
//     console.log(typeof props.location.state.schedulerData.ScheduledDateTime);
//     console.log(typeof '0 0 00 ? * MON *');
//     console.log(typeof cronValue);
//   }, []);

//   function updateName(event) {
//     setName(event.target.value);
//   }
//   function updateEndpointId(event) {
//     setEndpointId(event.target.value);
//     var index = event.nativeEvent.target.selectedIndex;
//     setEndpointName(event.nativeEvent.target[index].text);
//   }
//   function updateCronValue(event) {
//     setCronValue(event.target.value);
//   }
//   function updateExecute(event) {
//     setExecute(event.target.value);
//   }
//   function onCreateScheduler(event) {
//     event.preventDefault();
//     props.createScheduler(name, cronValue, execute, endpointId);
//     props.history.push('/scheduler');
//   }
//   function onEditScheduler() {
//     event.preventDefault();
//     props.updateScheduler(
//       Id,
//       name,
//       endpointId,
//       cronValue,
//       endpointName,
//       execute,
//     );
//     props.history.push('/scheduler');
//   }
// }
class PopupContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            endpoints: [],
        };
    }

    componentDidMount() {
        this.props.fetchEndpoints();
    }

    render() {
        return <SchedulerForm {...this.props} />;
    }
}

const mapStateToProps = state => ({
    endpoints: state.endpoints.endpoints,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchEndpoints: fetchEndpoints,
            createScheduler: createScheduler,
            updateScheduler: updateScheduler,
        },
        dispatch,
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PopupContent);
