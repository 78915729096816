// import { getOptions } from "../utils/axios";
import { AUTH_URL } from "../utils/Url";
import axios from "axios";
import Cookies from "js-cookie";
const connected = Cookies.get("connect.sid");
const uuidv1 = require("uuid/v1");

const getOptions = () => {
    return {
        withCredentials: true,
        UserInfo:localStorage.getItem("UserInfo"),
        Accept: "application/json",
        "Content-Type": "application/json",
        traceId: uuidv1(),
        headers: {
            // "Accept": "/",
            // "Cache-Control": "no-cache",
             "Content-Type": "application/json",
            // Authorization: "getJwt()",
            // "Content-Type": "application/json",
            // traceId: uuidv1(),
           
            "Cookie": document.cookie,
          
        },
    }
}

axios.interceptors.response.use(
    (response) => {
        if (response && response.data && response.data.message == "AuthenticationFailure") 
            window.location.replace(`${AUTH_URL}/auth/openid`);
        else 
            return response;
    },
    (error) => {
        if ( error.response && error.response.data && error.response.data.message == "AuthenticationFailure")
            window.location.replace(`${AUTH_URL}/auth/openid`);
        else {
            if (error.response && error.response.data) {
                console.log("------------------RESPONSE FROM INTERCEPTORS----------------")
                console.log(error.response)
                console.log("------------------RESPONSE FROM INTERCEPTORS----------------")
                // let string = constructErrorMesssage(error.response.data);
                // store.dispatch(setServerErrorFlag(true))
                // store.dispatch(setServerErrorMessage(string));
                // setTimeout(() => {
                // store.dispatch(setServerErrorFlag(false))
                // store.dispatch(setServerErrorMessage(""))
                // }, 3000)
            }
            return Promise.reject(error);
        }
    }
);


export const apiAdapter =  (baseURL) => {
  //axios.defaults.withCredentials = true;
    return axios.create({
        baseURL: baseURL,
        // data: data,
        withCredentials:true,
        UserInfo:localStorage.getItem("UserInfo"),
        headers: getOptions()
    });
};
